import * as React from "react"
import '../../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../../fonts/font-awesome/css/font-awesome.min.css";
import "../../css/template.css";
import "../../css/responsive.css";
import "../../css/base-sizing.css";
import "../../css/custom.css";
import "../../css/knowledge.css";

import HeaderAndMenu from "../../page_components/common/HeaderAndMenu";
import Footer from "../../page_components/common/Footer";
import BsHelmet from "../../page_components/common/BsHelmet";
import CommonHeroLow from "../../page_components/common/CommonHeroLow";
import BreadCrumbs from "./breadcrumbs";
import {StaticImage} from "gatsby-plugin-image";
import {useEffect} from "react";
import initStickyMenu from "../../page_components/common/initStickyMenu";


const Messaging = () => {
    useEffect(() => {
        initStickyMenu();
    }, []);

    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"BitSpreader Documentation - Messaging"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <CommonHeroLow title={"BitSpreader Documentation - Messaging"}/>
                <BreadCrumbs link={"messaging"} title="Messaging"/>
                <section className="hg_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div id="stickymenu" className="sidebar knowledgebase">
                                    <div className="widget widget_categories">
                                        <h3 className="widgettitle title">SECTIONS</h3>
                                        <a href="#messaging">Messaging</a>
                                        <ul className="menu">
                                            <li><a href="#mailbox">Mailbox</a></li>
                                            <li><a href="#requiresAck">Messages requiring confirmation</a></li>
                                            <li><a href="#notifications">Notifications</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-8"}>
                                <h2 id="stickyref" className="kb-article--title">
                                    <a name={"messaging"}>Messaging</a>
                                </h2>
                                <br/><br/>
                                <a name={"mailbox"}><h4>Mailbox</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        You can display messages waiting for you to read by clicking the green envelope button in the top right corner next to your email address or by selecting the
                                        “Messages” option in the menu after clicking the avatar icon.

                                    </div>
                                    <div className={'col-md-3'}>
                                        <StaticImage src={"../../gatsbyimg/docs/openmailbox.png"} alt={"Registration#1"} className={"border"}/>
                                    </div>
                                    <div className={'col-md-3'}>
                                        <StaticImage src={"../../gatsbyimg/docs/messagingIcon.png"} alt={"Registration#1"} className={"border"}/>
                                    </div>
                                </div>
                                <div className={"row pt-20"}>
                                    <div className={"col-md-12 pt-10"}>
                                        <StaticImage src={"../../gatsbyimg/docs/mailbox.png"} alt={"Registration#1"} className={"border"}/>
                                    </div>
                                </div>
                                <div className={"row pt-20"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        After clicking the message you should see the message dialog, that you can close with the “OK” button after reading the message.

                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/message.png"} alt={"Registration#1"} className={"border"}/>
                                    </div>
                                </div>
                                <br/>

                                <a name={"requiresAck"}><h4>Messages requiring confirmation</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        Some of the messages we send you may require you to confirm that you accept them. They will pop-up instantly after you login to the platform. You will not be able to continue trading until you accept these messages.
                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/messageRequiresAck.png"} alt={"Registration#1"} className={"border"}/>
                                    </div>
                                </div>
                                <br/>

                                <a name={"notifications"}><h4>Notifications</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        Notifications about system-wide will be displayed in the top-left corner of the screen (click to show notification contents) - no need for accepting them, they will disappear automatically after the maintenance event is over
                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/notifications.png"} alt={"Registration#1"} className={"border"}/>
                                    </div>
                                </div>
                                <br/>

                            </div>

                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default Messaging;
